import Layout from '../layout/layout';
import React, { ComponentType, FC } from 'react';
import { Helmet } from 'react-helmet';
import { HeroHeader } from './common/HeroHeader';
import { ResourcesGrid } from './common/ResourcesGrid';
import { graphql } from 'gatsby';
import { GatsbyTemplateProps } from '../../common/interfaces/gatsby-template-props.interface';
import { AppContextProps } from '../../common/interfaces/locale-props.interface';
import { ResourceGrid, WelcomeCenterTab } from './interfaces/welcome-center.interface';
import { ContentfulBladeSubNav } from '../../contentful/content-types/blades/blade-sub-nav';
import { BladeSubNav } from '../..';
import { useCMSState } from '@internal/useCMSHook';
import { useConfiguration } from '../../common/hooks/useConfiguration';
import { MainFooter } from '../layout/refreshed-footer/footer.interfaces';
import { ContentfulNavNavigationGroup } from '../../contentful/content-types/nav-navigation-group';

interface IIframe {
  url: string;
  src?: string;
  allowFullScreen?: boolean;
  position?:
    | 'relative'
    | 'absolute'
    | 'fixed'
    | 'sticky'
    | 'static'
    | 'inherit'
    | 'initial'
    | 'unset';
  display?: 'block' | 'none' | 'inline';
  height?: string;
  width?: string;
  loading?: 'auto' | 'eager' | 'lazy';
  target?: string;
  importance?: 'auto' | 'high' | 'low';
  overflow?: string;
  styles?: object;
  name?: string;
  allowpaymentrequest?: boolean;
  referrerpolicy?:
    | 'no-referrer'
    | 'no-referrer-when-downgrade'
    | 'origin'
    | 'origin-when-cross-origin'
    | 'same-origin'
    | 'strict-origin'
    | 'strict-origin-when-cross-origin'
    | 'unsafe-url';
  onLoad?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  frameBorder?: number;
  scrolling?: 'auto' | 'yes' | 'no';
  id?: string;
  ariaHidden?: boolean;
  ariaLabel?: string;
  ariaLabelledby?: string;
  sandbox?:
    | 'allow-forms'
    | 'allow-modals'
    | 'allow-orientation-lock'
    | 'allow-pointer-lock'
    | 'allow-popups'
    | 'allow-popups-to-escape-sandbox'
    | 'allow-presentation'
    | 'allow-same-origin'
    | 'allow-scripts'
    | 'allow-storage-access-by-user-activation'
    | 'allow-top-navigation'
    | 'allow-top-navigation-by-user-activation';
  allow?: string;
  className?: string;
  title?: string;
}

const Iframe: ComponentType<IIframe> = ({
  url,
  allowFullScreen,
  position,
  display,
  height,
  width,
  overflow,
  styles,
  onLoad,
  onMouseOver,
  onMouseOut,
  scrolling,
  id,
  frameBorder,
  ariaHidden,
  sandbox,
  allow,
  className,
  title,
  ariaLabel,
  ariaLabelledby,
  name,
  target,
  loading,
  importance,
  referrerpolicy,
  allowpaymentrequest,
  src,
}: IIframe) => {
  const defaultProps = Object.assign({
    src: src || url,
    target: target || null,
    display: 'initial',
    style: {
      position: position || null,
      display: display || 'block',
      overflow: overflow || null,
    },
    scrolling: scrolling || null,
    allowpaymentrequest: allowpaymentrequest || null,
    importance: importance || null,
    sandbox: sandbox || null,
    loading: loading || null,
    styles: styles || null,
    name: name || null,
    className: className || null,
    referrerpolicy: referrerpolicy || null,
    title: title || null,
    allow: allow || null,
    id: id || null,
    'aria-labelledby': ariaLabelledby || null,
    'aria-hidden': ariaHidden || null,
    'aria-label': ariaLabel || null,
    width: width || null,
    height: height || null,
    onLoad: onLoad || null,
    onMouseOver: onMouseOver || null,
    onMouseOut: onMouseOut || null,
  });
  let props = Object.create(null);
  for (let prop of Object.keys(defaultProps)) {
    if (defaultProps[prop] != null) {
      props[prop] = defaultProps[prop];
    }
  }

  for (let i of Object.keys(props.style)) {
    if (props.style[i] == null) {
      delete props.style[i];
    }
  }

  if (allowFullScreen) {
    if ('allow' in props) {
      const currentAllow = props.allow.replace('fullscreen', '');
      props.allow = `fullscreen ${currentAllow.trim()}`.trim();
    } else {
      props.allow = 'fullscreen';
    }
  }

  if (frameBorder && frameBorder >= 0) {
    if (!props.style.hasOwnProperty('border')) {
      props.style.border = frameBorder;
    }
  }
  return <iframe {...props} />;
};

export const query = graphql`
  query($locale: String!) {
    headerData: navNavigationGroup(
      contentfulId: { eq: "3hzSjT58plRWzuQrC4j4ny" }
      locale: { eq: $locale }
    ) {
      ...navNavigationGroupFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
    subnav: bladeSubNav(contentfulId: { eq: "1e0eITYdkm5xNNEvoFpLzK" }, locale: { eq: $locale }) {
      ...subnavFragment
    }

    welcomeCenter(tabName: { eq: "health-check" }, locale: { eq: $locale }) {
      ...welcomeCenterFragment
    }

    resourceGrid: bladeInfoBlocks(
      contentfulId: { eq: "5qZqpdha26WtvVPri2e2t7" }
      locale: { eq: $locale }
    ) {
      title
      contentfulId
      relatedInfoBlocks {
        title
        description
        linkText
        linkUrl
      }
    }
  }
`;

export interface HealthCheckProps extends GatsbyTemplateProps<AppContextProps> {
  data: {
    headerData: ContentfulNavNavigationGroup;
    footerData: MainFooter;
    welcomeCenter: WelcomeCenterTab;
    subnav: ContentfulBladeSubNav;
    resourceGrid: ResourceGrid;
  };
}

const HealthCheck: FC<HealthCheckProps> = ({ data, pageContext }) => {
  const config = useConfiguration();
  let { headerData, footerData, resourceGrid, subnav, welcomeCenter } = data;
  if (config.targetEnv === 'staging') {
    footerData = useCMSState<MainFooter>(footerData);
    headerData = useCMSState<ContentfulNavNavigationGroup>(headerData);
    subnav = useCMSState<ContentfulBladeSubNav>(subnav);
    welcomeCenter = useCMSState<WelcomeCenterTab>(welcomeCenter);
    resourceGrid = useCMSState<ResourceGrid>(resourceGrid);
  }
  const { mainHeader, subHeader } = welcomeCenter;
  return (
    <Layout headerData={headerData} pageContext={pageContext} footerData={footerData}>
      <Helmet>
        <title>Welcome Center | Cloudflare</title>
      </Helmet>

      <HeroHeader title={mainHeader} subtitle={subHeader}></HeroHeader>

      <BladeSubNav blade={subnav} pagePrefix={'/'} />

      <div className="flex mw9 center pv4 flex-column ph4 ph0-l">
        <h3 className="mb0 f4 b nb4-l tc">{}</h3>
        <Iframe
          url="https://www.cloudflare.com/embed/diagnostic-center/"
          height="800px"
          className="pb6"
        />
      </div>

      <ResourcesGrid
        resourceGrid={{
          title: resourceGrid.title,
          resources: resourceGrid.relatedInfoBlocks,
        }}
      ></ResourcesGrid>
    </Layout>
  );
};
export default HealthCheck;
